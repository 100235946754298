import React from 'react'
import ReactModal from "react-modal"

export const StyledReactModalDefaultClass = 'system-ui-modal'

export const StyledReactModalDefaultStyles : ReactModal.Styles  = {
    content: {
        verticalAlign: 'middle',
        position: 'absolute',
        inset: '50% auto auto 50%',
        transform: 'translate(-50%,-50%)',
        zIndex: 999999,
        maxWidth: '500px',
        boxSizing: 'border-box',
        width: '90%',
        background: '#fff',
        padding: 0,
        border: 'none',
        borderRadius: '8px',
        boxShadow: '0 0 10px #000',
        textAlign: 'left',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 999999,
    },
}

export default function StyledReactModal(props: ReactModal.Props) {
    const newProps = {
        ...props,
        className: mergeClassName(StyledReactModalDefaultClass, props.className),
        style: mergeStyle(StyledReactModalDefaultStyles, props.style),
    }
    // console.log(newProps)
    return React.createElement(ReactModal, {...newProps})
}

function mergeClassName(base: string, className: string | ReactModal.Classes | undefined) {
    if (typeof className === 'object') {
        return {
            ...className,
            base: base + ' ' + className.base,
        }
    } else if (typeof className === 'string') {
        return base + ' ' + className
    } else {
        return base
    }
}

function mergeStyle(base: ReactModal.Styles, style: ReactModal.Styles | undefined) {
    return {
        content: {
            ...base.content,
            ...style?.content,
        },
        overlay: {
            ...base.overlay,
            ...style?.overlay,
        },
    }
}
