import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import withSecurityCheck, {CheckAndActionProps} from "@shared/security/SecurityCheck"
import {useSubmitEmulation} from "@shared/security/SecurityCheckForm"
import compress from '@shared/util/compress'
import useLocalStateUseStore from "@shared/util/zustand-hook"
import {SyntheticEvent, useEffect, useRef} from 'react'
import {StateCreator} from "zustand/vanilla"

type ErrorState = 'invalid-file-num' | 'unmodified-oversize' | 'measuring-failed' | 'resize-failed' | 'submit-failed'
type ProcessState = 'unmodified' | 'measuring' | 'measured' | 'resizing' | 'resized' | ErrorState

interface UploaderState {
    loading: string | null
    warning: string[]
    alert: string | null
    processState: ProcessState
    resizedFileFormData: FormData | null
    autoWidth: string | null
    resetToDefault(): void
    startMeasuring(): void
    finishMeasuring(autoWidth: string|null): void
    startResizing(): void
    finishResizing(data: FormData): void
    alertUnexpectedState(alert: string, reason: ErrorState): void
    upload(form: HTMLFormElement, actionUrl: string): void
}

const uploaderStateCreator: StateCreator<UploaderState> = (set, get) => ({
    loading: null,
    warning: [],
    alert: null,
    processState: 'unmodified',
    resizedFileFormData: null,
    autoWidth: null,
    startMeasuring() {
        set({
            loading: "画像を読込中...",
            alert: null,
            processState: 'measuring',
            resizedFileFormData: null,
        })
    },
    finishMeasuring(autoWidth) {
        set({
            loading: null,
            warning: autoWidth
                ? [...get().warning, "画像の幅/高さが大きいため自動でサイズオプションを付与します。"]
                : get().warning,
            alert: null,
            processState: 'measured',
            resizedFileFormData: null,
            autoWidth: autoWidth,
        })
    },
    resetToDefault() {
        set({
            warning: [],
            alert: null,
            processState: 'unmodified',
            resizedFileFormData: null,
        })
    },
    startResizing() {
        set({
            loading: "画像を縮小中...",
            alert: null,
            processState: 'resizing',
            resizedFileFormData: null,
        })
    },
    finishResizing(data) {
        set({
            loading: null,
            warning: [...get().warning, "ファイルサイズの上限を超えているため、縮小した画像を送信します。"],
            alert: null,
            processState: 'resized',
            resizedFileFormData: data,
        })
    },
    alertUnexpectedState(alert, reason) {
        set({
            loading: null,
            warning: [],
            alert: alert,
            processState: reason,
            resizedFileFormData: null,
        })
    },
    upload(form, actionUrl) {
        const {processState, resizedFileFormData, alertUnexpectedState} = get()
        if (processState === 'unmodified' || processState === 'measured') {
            form.submit()
            return
        }
        if (processState !== 'resized') {
            return
        }
        // jQuery !!!!!
        $.ajax({
            url: actionUrl,
            type: "POST",
            data: resizedFileFormData,
            dataType: 'json',
            processData: false,  // jQuery がデータを処理しないよう指定
            contentType: false,   // jQuery が contentType を設定しないよう指定
        }).done((data) => {
            if (data.hasOwnProperty('redirect')) {
                location.assign(data.redirect)
            } else {
                location.reload()
            }
        }).fail(() => {
            alertUnexpectedState("縮小画像の送信エラー", 'submit-failed')
            // console.dir(data)
        })
    }
})

export interface UploaderProps {
    actionUrl: string
    page: string
    hiddenArgs: Record<string, any>
    attachFileInputName: string
    maxFileSizeInputName: string
    compressorQuality: number
    compressorMaxWidth: number
    compressorMaxHeight: number
    maxFileSize: number
    adminOnly: boolean
    passwordRequired: boolean
    submitName: string | null
    widthRestriction: boolean
}

const Uploader = withSecurityCheck(_Uploader)
export default Uploader

const REQUIRE_SIZE_OPTION_THRESHOLD = 300

/**
 * アップロード画像の自動縮小を行うコンポーネント
 */
function _Uploader({
    actionUrl,
    hiddenArgs, attachFileInputName, maxFileSize, maxFileSizeInputName,
    compressorQuality, compressorMaxWidth, compressorMaxHeight,
    passwordRequired, adminOnly, submitName, widthRestriction,
    onAction, isCheckComplete,
}: UploaderProps & CheckAndActionProps) {
    const useStore = useLocalStateUseStore(uploaderStateCreator)
    const {
        loading, warning, alert, autoWidth,
        startMeasuring, finishMeasuring,
        resetToDefault, startResizing, finishResizing, alertUnexpectedState, upload,
    } = useStore()

    const compressTargetParam = {
        maxFileSize,
        compressorQuality,
        compressorMaxWidth,
        compressorMaxHeight,
    }

    const formRef = useRef<HTMLFormElement>()

    const onFileInputChange = async (e: SyntheticEvent) => {
        const target = e.currentTarget
        if (!(target instanceof HTMLInputElement)) {
            return
        }
        const form = formRef.current
        if (!(form instanceof HTMLFormElement)) {
            return
        }
        // UploaderState に入れたいけど props で渡されたパラメーターが多すぎるなあ
        if (target.files.length === 0) {
            resetToDefault()
            return
        }

        if (target.files.length > 1) {
            alertUnexpectedState('複数のファイルを選択することはできません。', 'invalid-file-num')
            return
        }

        let file = target.files.item(0)
        const isImage = file.type.startsWith('image/')
        const restrictWidth = isImage && widthRestriction

        if (restrictWidth) {
            startMeasuring()
            try {
                const {width, height} = await getImageSize(file)
                const autoWidth = calcAutoWidth(width, height)
                finishMeasuring(autoWidth)
            } catch (err) {
                alertUnexpectedState('画像の読み込みに失敗', 'measuring-failed')
                return
            }
        }

        const sizeOver = file.size > maxFileSize

        if (sizeOver) {
            if (isImage) {
                startResizing()
                compress(file, 1, compressTargetParam, (result) => {
                    const formData = encodeResizeResultAsFormData(
                        result, form,
                        attachFileInputName, maxFileSizeInputName, maxFileSize,
                    )
                    finishResizing(formData)
                }, (err) => {
                    alertUnexpectedState('画像の縮小に失敗：' + err.message, 'resize-failed')
                })
            } else {
                // 画像でない場合
                alertUnexpectedState("ファイルサイズの上限を超えています。別のファイルを選択してください。", 'unmodified-oversize')
            }
        }

        if (!restrictWidth && !sizeOver) {
            resetToDefault()
        }
    }

    const submitterData = useSubmitEmulation(formRef)

    useEffect(() => {
        if (isCheckComplete) {
            upload(formRef.current, actionUrl)
        }
    }, [isCheckComplete])

    return (
        <form action={actionUrl} method="post" encType="multipart/form-data" className="attach-form"
              onSubmit={onAction} ref={formRef}>
            {Object.entries(hiddenArgs).map(([key, value]) => {
                if (typeof value === 'string' || typeof value === 'number') {
                    return <input type="hidden" name={key} value={value}/>
                }
            })}
            {autoWidth && <input type="hidden" name="auto_width" value={autoWidth}/>}
            <input type="hidden" name={maxFileSizeInputName} value={maxFileSize}/>
            <span className="small">アップロード可能最大ファイルサイズは {maxFileSize / 1024}KB です。サイズを超過する画像は自動的に調整/変換されます。</span><br/>
            <label htmlFor="_p_attach_file">添付ファイル:</label>
            <input type="file" name="attach_file" id="_p_attach_file" onChange={onFileInputChange}/>
            {(passwordRequired || adminOnly) && [
                <br/>,
                adminOnly ? '管理者パスワード: ' : 'パスワード: ',
                <input type="password" name="pass" size={8}/>,
            ]}
            <input type="submit" value="アップロード" name={submitName}/>
            {loading
                && <div className="auto-downscale-help loading">{loading}</div>
            }
            {warning.length > 0 && warning.map(w =>
                <div className="auto-downscale-help warning">
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="auto-downscale-icon"/>
                    {w}
                </div>
            )}
            {alert && (
                <div className="auto-downscale-help alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="auto-downscale-icon"/>
                    {alert}
                </div>
            )}
            {submitterData && <input type="hidden" name={submitterData.name} value={submitterData.value} />}
        </form>
    )
}

function encodeResizeResultAsFormData(
    result: File,
    form: HTMLFormElement,
    attachFileInputName: string,
    maxFileSizeInputName: string,
    maxFileSize: number,
) {
    const formData = new FormData()
    // 縮小画像を含むフォームデータを作成する。
    // ファイル名には file.name でなく result.name を使う。（大きい png は jpg になるし、gif が png になる環境もある。）
    formData.append(attachFileInputName, result, result.name)
    formData.append(maxFileSizeInputName, maxFileSize.toString())
    // 元のフォームが持つ他のフィールドをコピー
    const origData = new FormData(form)
    const it = origData.entries()
    for (let entry = it.next(); !entry.done; entry = it.next()) {
        const k = entry.value[0]
        const v = entry.value[1]
        if (k !== attachFileInputName && k !== maxFileSizeInputName) {
            formData.append(k, v)
        }
    }
    return formData
}

function getImageSize(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight
            });
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
}

function calcAutoWidth(width: number, height: number): string|null {
    const longer = Math.max(width, height)
    if (longer < REQUIRE_SIZE_OPTION_THRESHOLD) {
        return null
    }

    const ratio = REQUIRE_SIZE_OPTION_THRESHOLD / longer

    return `${Math.round(width * ratio)}x${Math.round(height * ratio)}`
}
