import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EditAssistant} from '@shared/editAssistant'

import withSecurityCheck, {CheckAndActionProps} from "@shared/security/SecurityCheck"
import SecurityCheckForm from "@shared/security/SecurityCheckForm"
import {OperableTextFormComponentType} from '@shared/util/form-text-ops'
import useLocalStateUseStore from '@shared/util/zustand-hook'
import classNames from 'classnames'
import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {StateCreator} from 'zustand/vanilla'
import {createTextFormStore} from './_text-form-common'
import AutoResizeTextarea from './AutoResizeTextarea'

export interface PCommentFormProps {
    formActionPath: string
    commentPageUrl: string
    commentPage: string
    commentPageExists: boolean
    linkedPageName: string
    noDate: '1' | '0'
    noName: boolean
    dir: 1 | 0
    count: number
    reply: boolean
    capacityWarning: string
    commentsElement: HTMLElement | undefined
    newPageName: string
    archivePageName: string
    digest: string
}

const ROTATION_SUBMITTER = 'rotation'

const ROTATION_METHOD_SWITCH = 'switch'
const ROTATION_METHOD_ARCHIVE = 'archive'
const ROTATION_METHOD_EDIT = 'edit'
type RotationMethod = typeof ROTATION_METHOD_SWITCH | typeof ROTATION_METHOD_ARCHIVE | typeof ROTATION_METHOD_EDIT

interface RotationFormStoreState {
    rotationFormShown: boolean
    rotationMethod: RotationMethod
    newPageName: string
    archivePageName: string
    setRotationFormShown(shown: boolean): void
    setRotationMethod(method: RotationMethod): void
    setNewPageName(name: string): void
    setArchivePageName(name: string): void
}

const rotationFormStoreCreatorFrom =
    (newPageName: string, archivePageName: string): StateCreator<RotationFormStoreState> => {
    return (set, get) => ({
        rotationFormShown: false,
        rotationMethod: ROTATION_METHOD_SWITCH,
        newPageName: newPageName,
        archivePageName: archivePageName,
        setRotationMethod(method: RotationMethod) {
            set({rotationMethod: method})
        },
        setRotationFormShown(shown: boolean) {
            set({rotationFormShown: shown})
        },
        setNewPageName(name: string) {
            set({newPageName: name})
        },
        setArchivePageName(name: string) {
            set({archivePageName: name})
        },
    })
}

function createRotationFormStore(newPageName: string, archivePageName: string) {
    return useLocalStateUseStore(rotationFormStoreCreatorFrom(newPageName, archivePageName))
}

const PCommentForm = withSecurityCheck(_PCommentForm)
export default PCommentForm

function _PCommentForm({
    formActionPath, commentPageUrl, commentPage, commentPageExists,
    linkedPageName, noDate, noName, dir, count, reply, capacityWarning,
    commentsElement, newPageName, archivePageName, digest,
    onAction, isCheckComplete,
}: PCommentFormProps & CheckAndActionProps) {
    const PCOMMENT_SIZE_NAME = 12
    const PCOMMENT_SIZE_MSG = 58

    const editorRef = useRef<OperableTextFormComponentType>()

    const [name, setName] = useState("")

    const useStore = createTextFormStore()
    const useRotationFormStore = createRotationFormStore(newPageName, archivePageName)
    const {editData} = useStore()
    const {rotationFormShown, setRotationFormShown} = useRotationFormStore()

    const [isEditAssistantShown, setIsEditAssistantShown] = useState(false)

    const link = <p>
        {commentPageExists ? `最新の${count}件を表示しています。` : 'コメントはありません。'}
        <PageLink url={commentPageUrl} pageExists={commentPageExists}
                  title={commentPage}>
            {commentPageExists ? 'コメントページを参照' : linkedPageName}
        </PageLink>
    </p>

    const commentListRef = useRef<HTMLDivElement>()
    useEffect(() => {
        if (!commentsElement || commentsElement.parentElement === commentListRef.current) {
            return
        }
        // console.log("render comments")
        commentListRef.current.appendChild(commentsElement)
    })
    // const [experimentState, setExperimentState] = useState(false)

    const handleOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            e.preventDefault()
        }
    }

    return <>
        <div>
            {/*{ <button onClick={() => {setExperimentState(!experimentState)}}>ぽちぽち</button> }*/}
            {dir === 1 && link}
            <SecurityCheckForm onSubmit={(event) => {
                onAction(event, name + "\n\n" + editData)
            }} isCheckComplete={isCheckComplete} action={formActionPath} method="post">
                {dir === 1 && <div className="pcomment-comments-container" ref={commentListRef}/>}
                <div className={classNames('pcommentform', {
                    'no-edit-assistant': !isEditAssistantShown,
                    'has-capacity-warning': capacityWarning !== "",
                })}>
                    <input type="hidden" name="nodate" value={noDate}/>
                    <input type="hidden" name="dir" value={dir}/>
                    <input type="hidden" name="count" value={count}/>
                    <div className={classNames({disabled: rotationFormShown})}>
                        {reply &&
                            // Property 'defaultChecked' does not exist on type 'HTMLAttributes<HTMLInputElement>'.
                            // ↑ 何故かエラーに。
                            // @ts-ignore
                            <input type="radio" name="reply" value="0" tabIndex={0} defaultChecked={true} />
                        }
                        {!noName &&
                            <input type="text" name="name" size={PCOMMENT_SIZE_NAME} placeholder="お名前"
                                   value={name} onChange={(e) => setName(e.currentTarget.value)}
                                   onKeyDown={handleOnKeyDown} disabled={rotationFormShown}/>
                        }
                        <AutoResizeTextarea useStore={useStore} name="msg" autoComplete="off" placeholder="コメント"
                                            cols={noName ? PCOMMENT_SIZE_NAME + PCOMMENT_SIZE_MSG : PCOMMENT_SIZE_MSG}
                                            ref={editorRef} onKeyDown={handleOnKeyDown}
                                            onFocus={() => setIsEditAssistantShown(true)}
                                            disabled={rotationFormShown}
                        />
                        <input type="submit" name="pcomment" value="挿入" disabled={rotationFormShown}/>
                    </div>

                    <div className={classNames('edit-assistant-area', {
                        shown: isEditAssistantShown,
                        hide: !isEditAssistantShown,
                    })}>
                        {capacityWarning !== ""
                            && <>
                            <div className="comment-capacity-warning">
                                {capacityWarning}
                            </div>
                            <button type="button" onClick={() => {
                                setRotationFormShown(true)
                                setIsEditAssistantShown(false)
                            }}>整理する</button>
                            </>
                        }
                        <div className="controls">
                            <EditAssistant inlineOnly={true} editorRef={editorRef}/>
                            <FontAwesomeIcon className="hide-edit-assistant" icon={['fas', 'angle-up']} size="lg"
                                             onClick={() => setIsEditAssistantShown(false)}/>
                        </div>
                    </div>
                    {rotationFormShown &&
                        <RotationForm useRotationFormStore={useRotationFormStore}
                                      digest={digest} commentPage={commentPage}/>
                    }
                </div>
                {dir === 0 && <div className="pcomment-comments-container" ref={commentListRef}/>}
            </SecurityCheckForm>
            {dir === 0 && link}
        </div>
    </>
}

function PageLink({url, pageExists, children, ...props}: {
    url: string
    pageExists: boolean
    children: ReactNode
    // noinspection JSUnusedLocalSymbols
    [prop: string]: any
}) {
    return <>
        {pageExists
            ? <a href={url} className="rel-wiki-page" {...props}>{children}</a>
            : <span className="noexists">{children}
                <a href={url} {...props}>?</a>
            </span>}
    </>
}

function RotationForm({useRotationFormStore, digest, commentPage}: {
    useRotationFormStore: UseBoundStore<StoreApi<RotationFormStoreState>>
    digest: string
    commentPage: string
}) {
    const {
        rotationMethod, setRotationMethod, setRotationFormShown,
        newPageName, setNewPageName, archivePageName, setArchivePageName,
    } = useRotationFormStore()

    const onRotationMethodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRotationMethod(e.currentTarget.value as RotationMethod)
    }

    return <div className="rotation-form">
        <p>
            <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="lg" style={{marginRight: '4px'}}/>
            以下のいずれかの方法でコメントページを整理できます。
        </p>
        <div className="rotation-form-radio">
            <label>
                <input type="radio" name="rotation-method" value={ROTATION_METHOD_SWITCH}
                       checked={rotationMethod === ROTATION_METHOD_SWITCH}
                       onChange={onRotationMethodChanged}
                />
                A. 新しいコメントページを作成して切り替える
            </label>
            <div className={classNames('page-name', {disabled: rotationMethod !== ROTATION_METHOD_SWITCH})}>
                <label>
                    新しいページ名:
                    <input type="text" name="new-page-name" value={newPageName}
                           onChange={(e) => setNewPageName(e.currentTarget.value)}
                           disabled={rotationMethod !== ROTATION_METHOD_SWITCH}/>
                </label>
            </div>

            <label>
                <input type="radio" name="rotation-method" value={ROTATION_METHOD_ARCHIVE}
                       checked={rotationMethod === ROTATION_METHOD_ARCHIVE}
                       onChange={onRotationMethodChanged}
                />
                B. コメントを過去ログとして保存し、現在のコメントページをクリアする
            </label>
            <div className={classNames('page-name', {disabled: rotationMethod !== ROTATION_METHOD_ARCHIVE})}>
                <label>
                    過去ログページ名:
                    <input type="text" name="archive-page-name" value={archivePageName}
                           onChange={(e) => setArchivePageName(e.currentTarget.value)}
                           disabled={rotationMethod !== ROTATION_METHOD_ARCHIVE}/>
                </label>
            </div>

            <label>
                <input type="radio" name="rotation-method" value={ROTATION_METHOD_EDIT}
                       checked={rotationMethod === ROTATION_METHOD_EDIT}
                       onChange={onRotationMethodChanged}
                />
                C. 手動で編集する
            </label>
        </div>
        <div>
            <input type="hidden" name="digest" value={digest}/>
            <input type="hidden" name="comment-page" value={commentPage}/>
            <button className="rotation-submit" type="submit" style={{marginRight: '16px'}}>整理</button>
            <button type="button" name={ROTATION_SUBMITTER} value={ROTATION_SUBMITTER}
                    onClick={() => setRotationFormShown(false)}>キャンセル
            </button>
        </div>
    </div>
}
