import React from 'react'
import {createRoot} from 'react-dom/client'
import PCommentForm from "./component/PCommentForm"

(function ($) {
    $.fn.extend({
        "pcommentForm": function (this: JQuery, options: Record<string, any>) {
            this.each(((i, elm) => {
                const $elm = $(elm)
                const params = collectParams($elm, options)

                // 内部要素を保った DOM 構造を隠して残しておき、VDOM をその隣に置いた要素で展開する。
                // こうすることで、内部要素が一時的に DOM からいなくなる時間がなくなり、それらが jQuery
                // プラグイン等の DOMContentLoaded 時に動く別の機能の対象として有効なのを維持できる。
                $elm.hide()
                const $placeholder = $('<div>').insertAfter(elm)
                createRoot($placeholder.get(0)).render(React.createElement(PCommentForm, {
                    ...params,
                    securityCheck: {
                        // // ダミー (HTML で設定せずコンポーネントで固定実装なので)
                        targetPage: $elm.data('comment-page'),
                        preCheckSms: false,
                        checkSms: true,
                        interruptType: $elm.data('interrupt-type'),
                        unfreezeUrl: $elm.data('unfreeze-url'),
                    },
                }))
            }))
        }
    })

    function collectParams(elm: JQuery, options: Record<string, any>) {
        return $.extend({
            formActionPath: elm.data('form-action-path'),
            commentPageUrl: elm.data('comment-page-url'),
            commentPage: elm.data('comment-page'),
            commentPageExists: !!elm.data('comment-page-exists'),
            linkedPageName: elm.data('linked-page-name'),
            noDate: elm.data('no-date'),
            noName: !!elm.data('no-name'),
            dir: elm.data('dir'),
            count: elm.data('count'),
            reply: !!elm.data('reply'),
            capacityWarning: elm.data('capacity-warning'),
            commentsElement: elm.find('.pcomment-form-comments').get()[0],
            newPageName: elm.data('new-page-name'),
            archivePageName: elm.data('archive-page-name'),
            digest: elm.data('digest'),
        }, options)
    }

})(jQuery)
